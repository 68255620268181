import { bottom } from '@popperjs/core';
import Chart from 'chart.js/auto';

export function AssetsChart(orgData) {
  // Get canvas and destroy existing chart
  const assetsChartCanvas = document.getElementById('assetsChartContainer');
  const existingChart = Chart.getChart(assetsChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Parse data
  const assetsData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    assetsData[year] = entry ? parseFloat(entry.assets) || null : null;
    assetsData[`${year}_avg`] = entry ? parseFloat(entry.avg_assets) || null : null;
  }

  // Generate chart data
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);
  const assetsChartData = yearLabels.map(year => ({
    year,
    total: assetsData[year] || null,
    avg: assetsData[`${year}_avg`] || null
  }));

  const total = assetsChartData.map(data => data.total);
  const average = assetsChartData.map(data => data.avg);

  
  // Create a chart in a specific container
  window.assetsChart = new Chart(assetsChartCanvas, {
    type: 'bar',
    options: {
      animation: true,
      maintainAspectRatio: true,
      responsive: true,
      aspectRatio: 1,
      layout: {
        autoPadding: true,
      },
      plugins: {
        legend: {
          display: false,
          position: bottom,
        },
        tooltip: {
          enabled: false,
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (context.dataset.label === 'Total' || context.dataset.label === 'Nat Avg') {
                label += ': ' + new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0
                }).format(context.parsed.y);
              }
              return label;
            }
          }
        }
      },
      scales: {
        x: {
          display: true,
          title: { display: false },
          axis: { display: false },
          grid: { display: false },
          borderColor: 'transparent',
          ticks: {
            callback: (_, index) => "'" + yearLabels[index].toString().slice(-2)
          }
        },
        total: { display: false },
        natAvg: { display: false },
      }
    },
    data: {
      labels: assetsChartData,
      datasets: [
        {
          label: 'Total',
          yAxisID: 'total',
          data: total,
          backgroundColor: 'rgba(224, 0, 0, .5)',
          hoverBackgroundColor: 'rgba(245, 75, 41, 1)', 
          borderRadius: Number.MAX_VALUE,
          barPercentage: 0.6,
          borderColor: 'transparent',
        },
        {
          label: 'Nat Avg',
          data: average,
          fill: false,
          borderColor: 'rgba(224, 0, 0, 1)',
          borderDash: [5, 5],
          pointRadius: 0,
          pointHoverRadius: 8,
          type: 'line',
          yAxisID: 'natAvg',
          xAxisID: 'x',
          spanGaps: true,
        }
      ]
    }
  });

  // Get the existing tooltip element from HTML
  const assetsTooltip = document.getElementById('assetsTooltip');

  // Function to find the latest non-null value in the assetsChartData array
  function findLatestNonNullValue() {
    for (let i = assetsChartData.length - 1; i >= 0; i--) {
      if (assetsChartData[i]?.total !== null) {
        return assetsChartData[i];
      }
    }
    return null;
  }

  // Function to update tooltip content
  function updateTooltipContent(data) {
    if (data) {
      assetsTooltip.textContent = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(data.total);
      assetsTooltip.style.visibility = 'visible';
    } else {
      assetsTooltip.style.visibility = 'hidden';
    }
  }

  // Update tooltip content initially with the latest value
  updateTooltipContent(findLatestNonNullValue());

  // Add event listener for mousemove event on the Filing Assets chart canvas
  assetsChartCanvas.addEventListener('mousemove', (event) => {
    const activePoint = assetsChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
    if (activePoint.length > 0) {
      updateTooltipContent(assetsChartData[activePoint[0].index]);
    } else {
      updateTooltipContent(findLatestNonNullValue());
    }
  });

  // Add event listener for mouseout event on the Filing Assets chart canvas
  assetsChartCanvas.addEventListener('mouseout', () => {
    updateTooltipContent(findLatestNonNullValue());
  });
}

// Function to create and display the FILING liabilities chart
export function LiabilitiesChart(orgData) {
  // Get the canvas element
  const liabilitiesChartCanvas = document.getElementById('liabilitiesChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(liabilitiesChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Parse filingData
  const liabilitiesData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    liabilitiesData[year] = entry ? parseFloat(entry.liabilities) || null : null;
    liabilitiesData[`${year}_avg`] = entry ? parseFloat(entry.avg_liabilities) || null : null;
  }

  // Generate labels for the x-axis
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);

  // Generate chart data
  const liabilitiesChartData = yearLabels.map(year => {
    return {
      year: year,
      total: liabilitiesData[year] || null,
      avg: liabilitiesData[`${year}_avg`] || null
    };
  });

  // Pre-define total liabilities and average liabilities arrays
  const total = liabilitiesChartData.map(data => data.total);
  const average = liabilitiesChartData.map(data => data.avg);

  // console.log('yearLabels:',yearLabels);
  console.log('liabilitiesData:', liabilitiesData);
  console.log('liabilitiesChartData:', liabilitiesChartData);
  console.log('liabilitiesTotal:', total);
  console.log('liabilitiesAverage:', average);

  
  // Create a chart in a specific container
  window.liabilitiesChart = new Chart(
    liabilitiesChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          legend: {
            display: false,
            position: bottom,
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.dataset.label === 'Total') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                } else if (context.dataset.label === 'Nat Avg') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true, // Display x-axis
            title: {
              display: false // Hide x-axis title
            },
            axis: {
              display: false // Hide x-axis line
            },
            grid: {
              display: false // Remove x-axis grid lines
            },
            borderColor: 'transparent',
            ticks: {
              callback: function(value, index, values) {
                return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
              }
            }
          },
          'total': {
          display: false, // Hide the default y-axis
          },
          'natAvg': {
            display: false,
            },
        }
      },
      data: {
        labels: liabilitiesChartData, // Use the generated labels
        datasets: [
          {
            label: 'Total',
            yAxisID: 'total',
            data: total,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          },
          {
            label: 'Nat Avg',
            data: average,
            fill: false, // Do not fill area under the line
            borderColor: 'rgba(224, 0, 0, 1)', // Line color
            borderDash: [5, 5],
            pointRadius: 0, // Hide data points
            pointHoverRadius: 8,
            type: 'line', // Chart type is line
            yAxisID: 'natAvg', // Use a separate y-axis for the line dataset
            xAxisID: 'x',
            spanGaps: true,
          }
        ]
      },
    }
  );

  // Get the existing tooltip element from HTML
  const liabilitiesTooltip = document.getElementById('liabilitiesTooltip');

  // Function to find the latest non-null value in the liabilitiesChartData array
  function findLatestNonNullValue() {
    for (let i = liabilitiesChartData.length - 1; i >= 0; i--) {
      if (liabilitiesChartData[i] !== null && liabilitiesChartData[i].total !== null) {
        return liabilitiesChartData[i];
      }
    }
    return null;
  }

  // Function to update tooltip content
  function updateTooltipContent(data) {
    if (data) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(data.total);
      liabilitiesTooltip.textContent = formattedValue;
      liabilitiesTooltip.style.visibility = 'visible';
    } else {
      liabilitiesTooltip.style.visibility = 'hidden';
    }
  }

  // Update tooltip content initially with the latest value
  updateTooltipContent(findLatestNonNullValue());

  // Add event listener for mousemove event on the Filing Liabilities chart canvas
  liabilitiesChartCanvas.addEventListener('mousemove', (event) => {
    const activePoint = liabilitiesChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      updateTooltipContent(liabilitiesChartData[index]);
    } else {
      updateTooltipContent(findLatestNonNullValue());
    }
  });

  // Add event listener for mouseout event on the Filing Liabilities chart canvas
  liabilitiesChartCanvas.addEventListener('mouseout', () => {
    updateTooltipContent(findLatestNonNullValue());
  });
}

// Function to create and display the FILING expenses chart
export function ExpensesChart(orgData) {
  // Get the canvas element
  const expensesChartCanvas = document.getElementById('expensesChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(expensesChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Parse filingData
  const expensesData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    expensesData[year] = entry ? parseFloat(entry.expenses) || null : null;
    expensesData[`${year}_avg`] = entry ? parseFloat(entry.avg_expenses) || null : null;
  }

  // Generate labels for the x-axis
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);

  // Generate chart data
  const expensesChartData = yearLabels.map(year => {
    return {
      year: year,
      total: expensesData[year] || null,
      avg: expensesData[`${year}_avg`] || null
    };
  });

  // Pre-define total expenses and average expenses arrays
  const total = expensesChartData.map(data => data.total);
  const average = expensesChartData.map(data => data.avg);

  // console.log('yearLabels:',yearLabels);
  console.log('expensesData:', expensesData);
  console.log('expensesChartData:', expensesChartData);
  console.log('expensesTotal:', total);
  console.log('expensesAverage:', average);

  
  // Create a chart in a specific container
  window.expensesChart = new Chart(
    expensesChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          legend: {
            display: false,
            position: bottom,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.dataset.label === 'Total') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                } else if (context.dataset.label === 'Nat Avg') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true, // Display x-axis
            title: {
              display: false // Hide x-axis title
            },
            axis: {
              display: false // Hide x-axis line
            },
            grid: {
              display: false // Remove x-axis grid lines
            },
            borderColor: 'transparent',
            ticks: {
              callback: function(value, index, values) {
                return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
              }
            }
          },
          'total': {
          display: false, // Hide the default y-axis
          },
          'natAvg': {
            display: false,
            },
        }
      },
      data: {
        labels: expensesChartData, // Use the generated labels
        datasets: [
          {
            label: 'Total',
            yAxisID: 'total',
            data: total,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          },
          {
            label: 'Nat Avg',
            data: average,
            fill: false, // Do not fill area under the line
            borderColor: 'rgba(224, 0, 0, 1)', // Line color
            borderDash: [5, 5],
            pointRadius: 0, // Hide data points
            pointHoverRadius: 8,
            type: 'line', // Chart type is line
            yAxisID: 'natAvg', // Use a separate y-axis for the line dataset
            xAxisID: 'x',
            spanGaps: true,
          }
        ]
      },
    }
  );

  // Get the existing tooltip element from HTML
  const expensesTooltip = document.getElementById('expensesTooltip');

  // Function to find the latest non-null value in the expensesChartData array
  function findLatestNonNullValue() {
    for (let i = expensesChartData.length - 1; i >= 0; i--) {
      if (expensesChartData[i] !== null && expensesChartData[i].total !== null) {
        return expensesChartData[i];
      }
    }
    return null;
  }

  // Function to update tooltip content
  function updateTooltipContent(data) {
    if (data) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(data.total);
      expensesTooltip.textContent = formattedValue;
      expensesTooltip.style.visibility = 'visible';
    } else {
      expensesTooltip.style.visibility = 'hidden';
    }
  }

  // Update tooltip content initially with the latest value
  updateTooltipContent(findLatestNonNullValue());

  // Add event listener for mousemove event on the Filing Expenses chart canvas
  expensesChartCanvas.addEventListener('mousemove', (event) => {
    const activePoint = expensesChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      updateTooltipContent(expensesChartData[index]);
    } else {
      updateTooltipContent(findLatestNonNullValue());
    }
  });

  // Add event listener for mouseout event on the Filing Expenses chart canvas
  expensesChartCanvas.addEventListener('mouseout', () => {
    updateTooltipContent(findLatestNonNullValue());
  });
}

// Function to create and display the FILING revenue chart
export function RevenueChart(orgData) {
  // Get the canvas element
  const revenueChartCanvas = document.getElementById('revenueChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(revenueChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Parse filingData
  const revenueData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    revenueData[year] = entry ? parseFloat(entry.revenue) || null : null;
    revenueData[`${year}_avg`] = entry ? parseFloat(entry.avg_revenue) || null : null;
  }

  // Generate labels for the x-axis
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);

  // Generate chart data
  const revenueChartData = yearLabels.map(year => {
    return {
      year: year,
      total: revenueData[year] || null,
      avg: revenueData[`${year}_avg`] || null
    };
  });

  // Pre-define total revenue and average revenue arrays
  const total = revenueChartData.map(data => data.total);
  const average = revenueChartData.map(data => data.avg);

  // console.log('yearLabels:',yearLabels);
  console.log('revenueData:', revenueData);
  console.log('revenueChartData:', revenueChartData);
  console.log('revenueTotal:', total);
  console.log('revenueAverage:', average);

  
  // Create a chart in a specific container
  window.revenueChart = new Chart(
    revenueChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          legend: {
            display: false,
            position: bottom,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.dataset.label === 'Total') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                } else if (context.dataset.label === 'Nat Avg') {
                  label += ': ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0
                  }).format(context.parsed.y);
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true, // Display x-axis
            title: {
              display: false // Hide x-axis title
            },
            axis: {
              display: false // Hide x-axis line
            },
            grid: {
              display: false // Remove x-axis grid lines
            },
            borderColor: 'transparent',
            ticks: {
              callback: function(value, index, values) {
                return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
              }
            }
          },
          'total': {
          display: false, // Hide the default y-axis
          },
          'natAvg': {
            display: false,
            },
        }
      },
      data: {
        labels: revenueChartData, // Use the generated labels
        datasets: [
          {
            label: 'Total',
            yAxisID: 'total',
            data: total,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          },
          {
            label: 'Nat Avg',
            data: average,
            fill: false, // Do not fill area under the line
            borderColor: 'rgba(224, 0, 0, 1)', // Line color
            borderDash: [5, 5],
            pointRadius: 0, // Hide data points
            pointHoverRadius: 8,
            type: 'line', // Chart type is line
            yAxisID: 'natAvg', // Use a separate y-axis for the line dataset
            xAxisID: 'x',
            spanGaps: true,
          }
        ]
      },
    }
  );

  // Get the existing tooltip element from HTML
  const revenueTooltip = document.getElementById('revenueTooltip');

  // Function to find the latest non-null value in the revenueChartData array
  function findLatestNonNullValue() {
    for (let i = revenueChartData.length - 1; i >= 0; i--) {
      if (revenueChartData[i] !== null && revenueChartData[i].total !== null) {
        return revenueChartData[i];
      }
    }
    return null;
  }

  // Function to update tooltip content
  function updateTooltipContent(data) {
    if (data) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(data.total);
      revenueTooltip.textContent = formattedValue;
      revenueTooltip.style.visibility = 'visible';
    } else {
      revenueTooltip.style.visibility = 'hidden';
    }
  }

  // Update tooltip content initially with the latest value
  updateTooltipContent(findLatestNonNullValue());

  // Add event listener for mousemove event on the Filing Revenue chart canvas
  revenueChartCanvas.addEventListener('mousemove', (event) => {
    const activePoint = revenueChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });
    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      updateTooltipContent(revenueChartData[index]);
    } else {
      updateTooltipContent(findLatestNonNullValue());
    }
  });

  // Add event listener for mouseout event on the Filing Revenue chart canvas
  revenueChartCanvas.addEventListener('mouseout', () => {
    updateTooltipContent(findLatestNonNullValue());
  });
}

// Function to create and display the FILING REV VS EXP chart
export function RevExpChart(orgData) {
  // Get the canvas element
  const revExpChartCanvas = document.getElementById('revExpChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(revExpChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Extract tax years and revenue data from orgData
  const revenueData = {};
  const expensesData = {};

  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    revenueData[year] = entry ? parseFloat(entry.revenue) || null : null;
    expensesData[year] = entry ? parseFloat(entry.expenses) || null : null;
  }

  // Combine revenue and expenses data into a single array of objects
  const revExpData = [];
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    const revenue = entry ? parseFloat(entry.revenue) : null;
    const expenses = entry ? parseFloat(entry.expenses) : null;

    // Push revenue and expenses data into revExpData array
    revExpData.push({ year, revenue: isNaN(revenue) ? null : revenue, expenses: isNaN(expenses) ? null : expenses });
  }

  // Ensure revExpData has at least one non-null revenue and expenses
  let latestRevenue = null;
  let latestExpenses = null;

  // Loop through revExpData from the end to find the latest non-null revenue and expenses
  for (let i = revExpData.length - 1; i >= 0; i--) {
    const dataPoint = revExpData[i];
    if (dataPoint.revenue !== null && !isNaN(dataPoint.revenue)) {
      latestRevenue = dataPoint.revenue;
    }
    if (dataPoint.expenses !== null && !isNaN(dataPoint.expenses)) {
      latestExpenses = dataPoint.expenses;
    }
    if (latestRevenue !== null && latestExpenses !== null) {
      break; // Exit loop if both latest revenue and expenses are found
    }
  }

// Update the existing revExpChartData array to include the latest revenue and expenses
const revExpChartData = [];
revExpData.forEach(dataPoint => {
  revExpChartData.push({ tax_year: dataPoint.year, revenue: dataPoint.revenue, expenses: dataPoint.expenses });
});

// Log the values of latestRevenue and latestExpenses
console.log('latestRevenue:', latestRevenue);
console.log('latestExpenses:', latestExpenses);

if (latestRevenue !== null || latestExpenses !== null) {
  revExpChartData.push({ tax_year: 'Latest', revenue: latestRevenue, expenses: latestExpenses });
}
console.log('revExpChartData:', revExpChartData);

  // Create a chart in a specific container
  window.revExpChart = new Chart(
    revExpChartCanvas,
    {
      type: 'doughnut',
      data: {
        labels: ['Revenue', 'Expenses'],
        datasets: [{
          label: 'Revenue vs Expense',
          data: [
            revExpChartData[revExpChartData.length - 1].revenue !== null ? revExpChartData[revExpChartData.length - 1].revenue : 0,
            revExpChartData[revExpChartData.length - 1].expenses !== null ? revExpChartData[revExpChartData.length - 1].expenses : 0
          ],
          backgroundColor: [
            'rgba(245, 75, 41, 1)', // Revenue color
            'rgba(224, 0, 0, 1)' // Expense color
          ],
          borderColor: 'transparent', // No border
          hoverOffset: 12,
          rotation: 180
        }]
      },
      options: {
        radius: '75%',
        cutout: '80%',
        borderRadius: 16,
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          overlappingSegments:{

          },
          legend: {
            display: true,
            position: bottom,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              // Update tooltip callback function
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                    label += ' No data';
                } else {
                    var revenue = latestRevenue;
                    var expenses = latestExpenses;
                    label += `Revenue: ${revenue}, Expenses: ${expenses}`;
                    console.log(label);
                }
                return label;
            }
            }
          }
        }
      }
    });


  // Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse event originated from the chart canvas
  const isOverChartCanvas = event.target === revExpChartCanvas;

  if (isOverChartCanvas) {
    // If the mouse is over the chart canvas
    const activePoint = revExpChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      const datasetIndex = activePoint[0].datasetIndex;
      const dataset = revExpChart.data.datasets[datasetIndex];
      const revenue = dataset.data[index];

      // Format revenue value
      const formattedRevenue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(revenue || 0);

      // Set the tooltip content to display revenue
      revExpTooltip.textContent = `${formattedRevenue}`;
      revExpTooltip.style.visibility = 'visible'; // Show the tooltip
    } else {
      // Hide the tooltip if no data point is hovered
      revExpTooltip.style.visibility = 'hidden';
    }
  } else {
    // If the mouse is not over the chart canvas
    // Display the percentage format of the latest values
    const diff = latestRevenue - latestExpenses;
    const formattedDiff = new Intl.NumberFormat('en-US', {
      style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(diff || 0);

    revExpTooltip.textContent = `${formattedDiff}`;
    revExpTooltip.style.visibility = 'visible'; // Show the tooltip
  }
});

// Add event listener for mouseout event on the Filing RevExp chart canvas
revExpChartCanvas.addEventListener('mouseout', () => {
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  revExpTooltip.style.visibility = 'hidden';
});
}

// Function to create and display the FILING ASSETS VS LIABILITIES chart
export function AssLibChart(orgData) {
  // Get the canvas element
  const assLibChartCanvas = document.getElementById('assLibChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(assLibChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Extract tax years and assets data from orgData
  const assetsData = {};
  const liabilitiesData = {};

  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    assetsData[year] = entry ? parseFloat(entry.assets) || null : null;
    liabilitiesData[year] = entry ? parseFloat(entry.liabilities) || null : null;
  }

  // Combine assets and liabilities data into a single array of objects
  const assLibData = [];
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    const assets = entry ? parseFloat(entry.assets) : null;
    const liabilities = entry ? parseFloat(entry.liabilities) : null;

    // Push assets and liabilities data into assLibData array
    assLibData.push({ year, assets: isNaN(assets) ? null : assets, liabilities: isNaN(liabilities) ? null : liabilities });
  }
  console.log(assLibData);

  // Ensure assLibData has at least one non-null assets and liabilities
  let latestAssets = null;
  let latestLiabilities = null;

  // Loop through assLibData from the end to find the latest non-null assets and liabilities
  for (let i = assLibData.length - 1; i >= 0; i--) {
    const dataPoint = assLibData[i];
    if (dataPoint.assets !== null && !isNaN(dataPoint.assets)) {
      latestAssets = dataPoint.assets;
    }
    if (dataPoint.liabilities !== null && !isNaN(dataPoint.liabilities)) {
      latestLiabilities = dataPoint.liabilities;
    }
    if (latestAssets !== null && latestLiabilities !== null) {
      break; // Exit loop if both latest assets and liabilities are found
    }
  }

// Update the existing assLibChartData array to include the latest assets and liabilities
const assLibChartData = [];
assLibData.forEach(dataPoint => {
  assLibChartData.push({ tax_year: dataPoint.year, assets: dataPoint.assets, liabilities: dataPoint.liabilities });
});

// Log the values of latestAssets and latestLiabilities
console.log('latestAssets:', latestAssets);
console.log('latestLiabilities:', latestLiabilities);

if (latestAssets !== null || latestLiabilities !== null) {
  assLibChartData.push({ tax_year: 'Latest', assets: latestAssets, liabilities: latestLiabilities });
}
console.log('assLibChartData:', assLibChartData);


  // Create a chart in a specific container
  window.assLibChart = new Chart(
    assLibChartCanvas,
    {
      type: 'doughnut',
      data: {
        labels: ['Assets', 'Liabilities'],
        datasets: [{
          label: 'Assets vs Liabilities',
          data: [
            assLibChartData[assLibChartData.length - 1].assets !== null ? assLibChartData[assLibChartData.length - 1].assets : 0,
            assLibChartData[assLibChartData.length - 1].liabilities !== null ? assLibChartData[assLibChartData.length - 1].liabilities : 0
          ],
          backgroundColor: [
            'rgba(245, 75, 41, 1)', // Assets color
            'rgba(224, 0, 0, 1)' // Liabilities color
          ],
          borderColor: 'transparent', // No border
          hoverOffset: 12
        }]
      },
      options: {
        radius: '75%',
        cutout: '80%',
        rotation: 180,
        borderRadius: 16,
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          overlappingSegments:{

          },
          legend: {
            display: true,
            position: bottom,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              // Update tooltip callback function
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                    label += ' No data';
                } else {
                    var assets = latestAssets;
                    var liabilities = latestLiabilities;
                    label += `Assets: ${assets}, Liabilities: ${liabilities}`;
                    console.log(label);
                }
                return label;
            }
            }
          }
        }
      }
    });


  // Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse event originated from the chart canvas
  const isOverChartCanvas = event.target === assLibChartCanvas;

  if (isOverChartCanvas) {
    // If the mouse is over the chart canvas
    const activePoint = assLibChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      const datasetIndex = activePoint[0].datasetIndex;
      const dataset = assLibChart.data.datasets[datasetIndex];
      const assets = dataset.data[index];

      // Format assets value
      const formattedAssets = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(assets || 0);

      // Set the tooltip content to display assets
      assLibTooltip.textContent = `${formattedAssets}`;
      assLibTooltip.style.visibility = 'visible'; // Show the tooltip
    } else {
      // Hide the tooltip if no data point is hovered
      assLibTooltip.style.visibility = 'hidden';
    }
  } else {
    // If the mouse is not over the chart canvas
    // Display the percentage format of the latest values
    const ratio = latestLiabilities / latestAssets;
    const formattedRatio = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(ratio || 0);

    assLibTooltip.textContent = `${formattedRatio}`;
    assLibTooltip.style.visibility = 'visible'; // Show the tooltip
  }
});

// Add event listener for mouseout event on the Filing AssLib chart canvas
assLibChartCanvas.addEventListener('mouseout', () => {
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  assLibTooltip.style.visibility = 'hidden';
});
}

// Function to create and display the FILING EMPLOYEE COUNT chart
export function TtlEmplyCntChart(orgData) {
  // Get the canvas element
  const ttlEmplyCntChartCanvas = document.getElementById('ttlEmplyCntChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(ttlEmplyCntChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }
  // Extract tax years and ttlEmplyCnt data from orgData
  const ttlEmplyCntData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    ttlEmplyCntData[year] = entry ? parseFloat(entry.employeecount) || null : null;
  }

  // Log the ttlEmplyCntData for debugging
  // console.log('ttlEmplyCntData:', ttlEmplyCntData);
  const data = ttlEmplyCntData;
  // console.log('data:', data);

  // Generate labels for the x-axis
  const labels = Object.keys(ttlEmplyCntData);
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);
  const ttlEmplyCntChartData = yearLabels.map(year => ttlEmplyCntData[year] || null); // Map ttlEmplyCnt data
  // console.log('yearLabels:',yearLabels);
  console.log('ttlEmplyCntChartData:',ttlEmplyCntChartData);

  // Create a chart in a specific container
  window.ttlEmplyCntChart = new Chart(
    ttlEmplyCntChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
          padding: 0,
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                  label += ' No data';
                } else {
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true, // Display x-axis
            title: {
              display: false // Hide x-axis title
            },
            axis: {
              display: false // Hide x-axis line
            },
            grid: {
              display: false // Remove x-axis grid lines
            },
            borderColor: 'transparent',
            ticks: {
              callback: function(value, index, values) {
                return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
              }
            }
          },
          y: {
            display: false // Hide y-axis
          }
        }
      },
      data: {
        labels: ttlEmplyCntChartData, // Use the generated labels
        datasets: [
          {
            label: 'TtlEmplyCnt',
            data: ttlEmplyCntChartData,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          }
        ]
      }
    }
  );

  // Get the existing tooltip element from HTML
const ttlEmplyCntTooltip = document.getElementById('ttlEmplyCntTooltip');

// Function to find the latest non-null value in the ttlEmplyCntChartData array
function findLatestNonNullValue() {
  for (let i = ttlEmplyCntChartData.length - 1; i >= 0; i--) {
    if (ttlEmplyCntChartData[i] !== null) {
      // console.log('Latest non-null value found:', ttlEmplyCntChartData[i]);
      return ttlEmplyCntChartData[i];
    }
  }
  console.log('No non-null value found, returning 0');
  return 0; // Return 0 if no non-null value is found
}

// Add event listener for mousemove event on the Filing TtlEmplyCnt chart canvas
ttlEmplyCntChartCanvas.addEventListener('mousemove', (event) => {
  // console.log('Mouse moved over FILING REV chart canvas');

  // Get the chart data under the mouse cursor
  const activePoint = ttlEmplyCntChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

  if (activePoint.length > 0) {
    // Get the index of the hovered data point
    const index = activePoint[0].index;
    // console.log('Hovered index:', index);

    // Update the tooltip content with the value of the hovered data point
    const formattedValue = new Intl.NumberFormat('en-US', {
    }).format(ttlEmplyCntChartData[index] || 0);
    ttlEmplyCntTooltip.textContent = formattedValue;
    // console.log("Tooltip content:", formattedValue);
    // Ensure the tooltip is visible
    ttlEmplyCntTooltip.style.visibility = 'visible';
  } else {
    // Hide the tooltip if no data point is hovered
    ttlEmplyCntTooltip.style.visibility = 'hidden';
  }
});

// Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse is over the Filing TtlEmplyCnt chart canvas
  const isOverChartCanvas = event.target === ttlEmplyCntChartCanvas;

  // If the mouse is not over the chart canvas, display the latest non-null value
  if (!isOverChartCanvas) {
    const latestValue = findLatestNonNullValue();
    const formattedLatestValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
    }).format(latestValue || 0);
    ttlEmplyCntTooltip.textContent = formattedLatestValue;
    // console.log("Tooltip content:", formattedLatestValue);
    // Ensure the tooltip is visible
    ttlEmplyCntTooltip.style.visibility = 'visible';
  }
});


// Add event listener for mouseout event on the Filing TtlEmplyCnt chart canvas
ttlEmplyCntChartCanvas.addEventListener('mouseout', () => {
  // console.log('Mouse left FILING REV chart canvas');
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  ttlEmplyCntTooltip.style.visibility = 'hidden';
});
}

// Function to create and display the FILING TOTAL COMPENSATION chart
export function TotalCompChart(orgData) {
  // Get the canvas element
  const totalCompChartCanvas = document.getElementById('totalCompChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(totalCompChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }
  // Extract tax years and totalComp data from orgData
  const totalCompData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    totalCompData[year] = entry ? parseFloat(entry.totalcomp) || null : null;
  }

  // Log the totalCompData for debugging
  // console.log('totalCompData:', totalCompData);
  const data = totalCompData;
  // console.log('data:', data);

  // Generate labels for the x-axis
  const labels = Object.keys(totalCompData);
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);
  const totalCompChartData = yearLabels.map(year => totalCompData[year] || null); // Map totalComp data
  // console.log('yearLabels:',yearLabels);
  console.log('totalCompChartData:',totalCompChartData);

  // Create a chart in a specific container
  window.totalCompChart = new Chart(
    totalCompChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                  label += ' No data';
                } else {
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true, // Display x-axis
            title: {
              display: false // Hide x-axis title
            },
            axis: {
              display: false // Hide x-axis line
            },
            grid: {
              display: false // Remove x-axis grid lines
            },
            borderColor: 'transparent',
            ticks: {
              callback: function(value, index, values) {
                return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
              }
            }
          },
          y: {
            display: false // Hide y-axis
          }
        }
      },
      data: {
        labels: totalCompChartData, // Use the generated labels
        datasets: [
          {
            label: 'Total Compensation',
            data: totalCompChartData,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          }
        ]
      }
    }
  );

  // Get the existing tooltip element from HTML
const totalCompTooltip = document.getElementById('totalCompTooltip');

// Function to find the latest non-null value in the totalCompChartData array
function findLatestNonNullValue() {
  for (let i = totalCompChartData.length - 1; i >= 0; i--) {
    if (totalCompChartData[i] !== null) {
      // console.log('Latest non-null value found:', totalCompChartData[i]);
      return totalCompChartData[i];
    }
  }
  // console.log('No non-null value found, returning 0');
  return 0; // Return 0 if no non-null value is found
}

// Add event listener for mousemove event on the Filing TotalComp chart canvas
totalCompChartCanvas.addEventListener('mousemove', (event) => {
  // console.log('Mouse moved over FILING REV chart canvas');

  // Get the chart data under the mouse cursor
  const activePoint = totalCompChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

  if (activePoint.length > 0) {
    // Get the index of the hovered data point
    const index = activePoint[0].index;
    // console.log('Hovered index:', index);

    // Update the tooltip content with the value of the hovered data point
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(totalCompChartData[index] || 0);
    totalCompTooltip.textContent = formattedValue;
    // console.log("Tooltip content:", formattedValue);
    // Ensure the tooltip is visible
    totalCompTooltip.style.visibility = 'visible';
  } else {
    // Hide the tooltip if no data point is hovered
    totalCompTooltip.style.visibility = 'hidden';
  }
});

// Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse is over the Filing TotalComp chart canvas
  const isOverChartCanvas = event.target === totalCompChartCanvas;

  // If the mouse is not over the chart canvas, display the latest non-null value
  if (!isOverChartCanvas) {
    const latestValue = findLatestNonNullValue();
    const formattedLatestValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(latestValue || 0);
    totalCompTooltip.textContent = formattedLatestValue;
    // console.log("Tooltip content:", formattedLatestValue);
    // Ensure the tooltip is visible
    totalCompTooltip.style.visibility = 'visible';
  }
});


// Add event listener for mouseout event on the Filing TotalComp chart canvas
totalCompChartCanvas.addEventListener('mouseout', () => {
  // console.log('Mouse left FILING REV chart canvas');
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  totalCompTooltip.style.visibility = 'hidden';
});

}

// Function to create and display the FILING AVERAGE COMPENSATION chart
export function AvgCompChart(orgData) {
  const avgCompChartCanvas = document.getElementById('avgCompChartContainer');

  const existingChart = Chart.getChart(avgCompChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  const avgCompData = {};
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    const totalComp = entry ? parseFloat(entry.totalcomp) || 0 : 0;
    const employeeCount = entry ? parseFloat(entry.employeecount) || 0 : 0;
    // Calculate average compensation for the year
    avgCompData[year] = employeeCount !== 0 ? totalComp / employeeCount : 0;
  }

  // Log the avgCompChartData for debugging
  // console.log('avgCompChartData:', avgCompChartData);
  const data = avgCompData;
  // console.log('data:', data);

  // Generate labels for the x-axis
  const labels = Object.keys(avgCompData);
  const yearLabels = Array.from({ length: 10 }, (_, i) => 2014 + i);
  const avgCompChartData = yearLabels.map(year => avgCompData[year] || null); // Map avgComp data
  console.log('avgCompYearLabels:',yearLabels);
  console.log('avgCompChartData:',avgCompChartData);

  window.avgCompChart = new Chart(
    avgCompChartCanvas,
    {
      type: 'bar',
      options: {
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                  label += ' No data';
                } else {
                  label += ': ' + context.parsed.y;
                }
                return label;
              }
            }
          }
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false
            },
            axis: {
              display: false
            },
            grid: {
              display: false
            },
            borderColor: 'transparent',
            ticks: {
                callback: function(value, index, values) {
                  return "'" + yearLabels[index].toString().slice(-2); // Use the generated labels
                }
            }
          },
          y: {
            display: false
          }
        }
      },
      data: {
        labels: avgCompChartData,
        datasets: [
          {
            label: 'Average Compensation',
            data: avgCompChartData,
            backgroundColor: 'rgba(224, 0, 0, .5)',
            hoverBackgroundColor: 'rgba(245, 75, 41, 1)',
            borderRadius: Number.MAX_VALUE,
            barPercentage: 0.6,
            borderColor: 'transparent',
          }
        ]
      }
    }
  );

  // Get the existing tooltip element from HTML
const avgCompTooltip = document.getElementById('avgCompTooltip');

// Function to find the latest non-null value in the avgCompChartData array
function findLatestNonNullValue() {
  for (let i = avgCompChartData.length - 1; i >= 0; i--) {
    if (avgCompChartData[i] !== null) {
      // console.log('Latest non-null value found:', avgCompChartData[i]);
      return avgCompChartData[i];
    }
  }
  // console.log('No non-null value found, returning 0');
  return 0; // Return 0 if no non-null value is found
}

// Add event listener for mousemove event on the Filing AvgComp chart canvas
avgCompChartCanvas.addEventListener('mousemove', (event) => {
  // console.log('Mouse moved over FILING REV chart canvas');

  // Get the chart data under the mouse cursor
  const activePoint = avgCompChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

  if (activePoint.length > 0) {
    // Get the index of the hovered data point
    const index = activePoint[0].index;
    // console.log('Hovered index:', index);

    // Update the tooltip content with the value of the hovered data point
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(avgCompChartData[index] || 0);
    avgCompTooltip.textContent = formattedValue;
    // console.log("Tooltip content:", formattedValue);
    // Ensure the tooltip is visible
    avgCompTooltip.style.visibility = 'visible';
  } else {
    // Hide the tooltip if no data point is hovered
    avgCompTooltip.style.visibility = 'hidden';
  }
});

// Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse is over the Filing AvgComp chart canvas
  const isOverChartCanvas = event.target === avgCompChartCanvas;

  // If the mouse is not over the chart canvas, display the latest non-null value
  if (!isOverChartCanvas) {
    const latestValue = findLatestNonNullValue();
    const formattedLatestValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    }).format(latestValue || 0);
    avgCompTooltip.textContent = formattedLatestValue;
    // console.log("Tooltip content:", formattedLatestValue);
    // Ensure the tooltip is visible
    avgCompTooltip.style.visibility = 'visible';
  }
});


// Add event listener for mouseout event on the Filing AvgComp chart canvas
avgCompChartCanvas.addEventListener('mouseout', () => {
  // console.log('Mouse left FILING REV chart canvas');
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  avgCompTooltip.style.visibility = 'hidden';
});

}

// Function to create and display the FILING COMPENSATION VS EXP chart
export function CompExpChart(orgData) {
  // Get the canvas element
  const compExpChartCanvas = document.getElementById('compExpChartContainer');

  // Destroy existing chart if it exists
  const existingChart = Chart.getChart(compExpChartCanvas);
  if (existingChart) {
    existingChart.destroy();
  }

  // Extract tax years and compensation data from orgData
  const compensationData = {};
  const expensesData = {};

  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    compensationData[year] = entry ? parseFloat(entry.compensation) || null : null;
    expensesData[year] = entry ? parseFloat(entry.expenses) || null : null;
  }

  // Combine compensation and expenses data into a single array of objects
  const compExpData = [];
  for (let year = 2014; year <= 2023; year++) {
    const entry = orgData.filingData.find(item => item.tax_year === year);
    const compensation = entry ? parseFloat(entry.totalcomp) : null;
    const expenses = entry ? parseFloat(entry.expenses) : null;

    // Push compensation and expenses data into compExpData array
    compExpData.push({ year, compensation: isNaN(compensation) ? null : compensation, expenses: isNaN(expenses) ? null : expenses });
  }

  // Ensure compExpData has at least one non-null compensation and expenses
  let latestCompensation = null;
  let latestExpenses = null;

  // Loop through compExpData from the end to find the latest non-null compensation and expenses
  for (let i = compExpData.length - 1; i >= 0; i--) {
    const dataPoint = compExpData[i];
    if (dataPoint.compensation !== null && !isNaN(dataPoint.compensation)) {
      latestCompensation = dataPoint.compensation;
    }
    if (dataPoint.expenses !== null && !isNaN(dataPoint.expenses)) {
      latestExpenses = dataPoint.expenses;
    }
    if (latestCompensation !== null && latestExpenses !== null) {
      break; // Exit loop if both latest compensation and expenses are found
    }
  }

// Update the existing compExpChartData array to include the latest compensation and expenses
const compExpChartData = [];
compExpData.forEach(dataPoint => {
  compExpChartData.push({ tax_year: dataPoint.year, compensation: dataPoint.compensation, expenses: dataPoint.expenses });
});

// Log the values of latestCompensation and latestExpenses
console.log('latestCompensation:', latestCompensation);
console.log('latestExpenses:', latestExpenses);

if (latestCompensation !== null || latestExpenses !== null) {
  compExpChartData.push({ tax_year: 'Latest', compensation: latestCompensation, expenses: latestExpenses });
}
console.log('compExpChartData:', compExpChartData);

  // Create a chart in a specific container
  window.compExpChart = new Chart(
    compExpChartCanvas,
    {
      type: 'doughnut',
      data: {
        labels: ['Comp', 'Expenses'],
        datasets: [{
          label: '',
          data: [
            compExpChartData[compExpChartData.length - 1].compensation !== null ? compExpChartData[compExpChartData.length - 1].compensation : 0,
            compExpChartData[compExpChartData.length - 1].expenses !== null ? compExpChartData[compExpChartData.length - 1].expenses : 0
          ],
          backgroundColor: [
            'rgba(245, 75, 41, 1)', // Compensation color
            'rgba(224, 0, 0, 1)' // Expense color
          ],
          borderColor: 'transparent', // No border
          hoverOffset: 12,
          rotation: 180
        }]
      },
      options: {
        radius: '75%',
        cutout: '80%',
        borderRadius: 16,
        animation: true,
        maintainAspectRatio: true,
        responsive: true,
        aspectRatio: 1,
        layout: {
          autoPadding: true,
        },
        plugins: {
          overlappingSegments:{

          },
          legend: {
            display: true,
            position: bottom,
          },
          tooltip: {
            enabled: false,
            callbacks: {
              // Update tooltip callback function
              label: function(context) {
                var label = context.dataset.label || '';
                if (context.parsed.y === null) {
                    label += ' No data';
                } else {
                    var compensation = latestCompensation;
                    var expenses = latestExpenses;
                    label += `Compensation: ${compensation}, Expenses: ${expenses}`;
                    console.log(label);
                }
                return label;
            }
            }
          }
        }
      }
    });


  // Add event listener for mousemove event on the document body
document.body.addEventListener('mousemove', (event) => {
  // Check if the mouse event originated from the chart canvas
  const isOverChartCanvas = event.target === compExpChartCanvas;

  if (isOverChartCanvas) {
    // If the mouse is over the chart canvas
    const activePoint = compExpChart.getElementsAtEventForMode(event, 'nearest', { intersect: true });

    if (activePoint.length > 0) {
      const index = activePoint[0].index;
      const datasetIndex = activePoint[0].datasetIndex;
      const dataset = compExpChart.data.datasets[datasetIndex];
      const compensation = dataset.data[index];

      // Format compensation value
      const formattedCompensation = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0
      }).format(compensation || 0);

      // Set the tooltip content to display compensation
      compExpTooltip.textContent = `${formattedCompensation}`;
      compExpTooltip.style.visibility = 'visible'; // Show the tooltip
    } else {
      // Hide the tooltip if no data point is hovered
      compExpTooltip.style.visibility = 'hidden';
    }
  } else {
    // If the mouse is not over the chart canvas
    // Display the percentage format of the latest values
    const ratio = latestCompensation / latestExpenses;
    const formattedRatio = new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(ratio || 0);

    compExpTooltip.textContent = `${formattedRatio}`;
    compExpTooltip.style.visibility = 'visible'; // Show the tooltip
  }
});

// Add event listener for mouseout event on the Filing CompExp chart canvas
compExpChartCanvas.addEventListener('mouseout', () => {
  // Hide the tooltip when the mouse pointer leaves the chart canvas
  compExpTooltip.style.visibility = 'hidden';
});


}

// Function to update and refresh all charts with new data
// export function updateCharts(orgData) {
  // // Update the ASSETS chart
  // const assetsData = orgData.bmfData.map(item => parseFloat(item.assets));
  // const assetsChart = Chart.getChart('assetsChartContainer');
  // assetsChart.data.datasets[0].data = assetsData;
  // assetsChart.update();

  // Update the EXPENSES chart
  // const expensesData = orgData.bmfData.map(item => parseFloat(item.expenses));
  // const expensesChart = Chart.getChart('expensesChartContainer');
  // expensesChart.data.datasets[0].data = expensesData;
  // expensesChart.update();
// }

// Function to update chart options by mutating
export function updateConfigByMutating(chart) {
  chart.options.plugins.title.text = 'New Title';
  chart.update();
}

// Function to update chart options as a new object
export function updateConfigAsNewObject(chart) {
  chart.options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Chart.js Bar Chart'
      },
      tooltip: {
        enabled: false
      }
    }
  };
  chart.update();
}
