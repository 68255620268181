const cleanMetadata = (info) => {
  if (!info) return { keywords: [], subject: '', title: '' };
  
  const rawSubject = info?.info?.Subject || '';
  
  // Return empty if just P or bplist
  if (/^(P|bplist00P?)$/.test(rawSubject.trim())) {
    return {
      keywords: info?.info?.Keywords?.split(',').map(k => k.trim()) || [],
      subject: '',
      title: info?.info?.Title || ''
    };
  }

  return {
    keywords: info?.info?.Keywords?.split(',').map(k => k.trim()) || [],
    subject: rawSubject
      .replace(/^bplist00[^A-Za-z]*/, '')
      .replace(/[\u0000-\u001F\u007F-\u00FF]/g, '')
      .replace(/\u[0-9a-fA-F]{4}/g, '')
      .replace(/[\x08\u0008\b]/g, '')
      .trim(),
    title: info?.info?.Title || ''
  };
};

document.addEventListener('DOMContentLoaded', function () {
  // Only run on samples page
  if (!window.location.pathname.includes('/samples.html')) {
    return;
  }
  // Block keyboard shortcuts
  document.addEventListener('keydown', function(e) {
    // Block Ctrl/Cmd + P (Print)
    if (e.key === 'p' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
    }
    // Block Ctrl/Cmd + U (View Source)
    if (e.key === 'u' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
    }
    // Block Ctrl/Cmd + S (Save)
    if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
    }
  });

  document.addEventListener('contextmenu', e => e.preventDefault());
  document.addEventListener('dragstart', e => e.preventDefault());

  const viewer = document.getElementById('viewer');
  const gridFilter = document.getElementById('grid-filter');
  const filterInput = document.getElementById('filterInput');

  let flkty = null;
  let detailFlkty = null;
  
  const viewerElements = {
    title: document.querySelector('#viewer .text-uppercase.text-dark'),
    container: document.getElementById('sampleViewer'),
    sampleSlider: document.getElementById('sampleSlider'),
    detailSlider: document.getElementById('detailSlider')
  };

  const scrollToFilter = () => {
    const filterTop = gridFilter.getBoundingClientRect().top;
    const offset = 100;
    window.scrollTo({
      top: window.scrollY + filterTop - offset,
      behavior: 'smooth'
    });
  };

  const handleScroll = () => {
    const pageTop = document.body.getBoundingClientRect().top;
    if (pageTop >= 0) {
      viewer.style.position = 'relative';
    }
  };

  const updateViewer = async (gridItem) => {
    console.log('1. Starting updateViewer');
    const thumbnail = gridItem.querySelector('img');
    
    try {
      // Clear existing
      if (flkty) {
        flkty.destroy();
        flkty = null;
      }
      if (detailFlkty) {
        detailFlkty.destroy();
        detailFlkty = null;
      }

      viewerElements.sampleSlider.innerHTML = '';
      viewerElements.detailSlider.innerHTML = '';
        
      // Load PDF and metadata
      const pdfPath = thumbnail.src.replace('/thumbnails/', '/').replace(/\.(jpg|png)$/, '.pdf');
      const pdf = await pdfjsLib.getDocument(pdfPath).promise;

      // Get metadata directly
      let metadata = { keywords: [], subject: '', title: '' };
      try {
        const info = await pdf.getMetadata();
        console.log('Raw PDF info:', info);
        metadata = cleanMetadata(info);
        console.log('Cleaned metadata:', metadata);
      } catch (metadataError) {
        console.error('Metadata extraction failed:', metadataError);
      }

      // Create detail cell
      const detailSlider = document.createElement('div');
      detailSlider.className = 'detail-slider w-100 h-100';

      const detailContent = document.createElement('div'); 
      detailContent.className = 'w-100 h-100 d-flex align-items-center justify-content-center';
      detailContent.style.overflow = 'scroll';
      detailContent.innerHTML = `
        <div class="col-12 px-4 text-left">
          <h6 class="text-uppercase text-secondary mb-5">${metadata.title || 'Untitled'}</h6>
          <blockquote class="mb-3">
            <p class="text-sm text-dark" style="text-align: justify;">${metadata.subject || 'No description available'}</p>
            <p class="h6 text-uppercase text-secondary mb-0">
              Tags: ${metadata.keywords.map(kw => 
                `<span class="keyword-tag lift" style="cursor: pointer;">${kw}</span>`
              ).join(', ') || 'None'}
            </p>
          </blockquote>
        </div>
      `;

      detailSlider.appendChild(detailContent);
      viewerElements.detailSlider.appendChild(detailSlider);

      // Add detailSlider tag filter click handlers
      const tags = detailContent.querySelectorAll('.keyword-tag');
      tags.forEach(tag => {
        tag.addEventListener('click', () => {
          const filterInput = document.getElementById('filterInput');
          filterInput.value = tag.textContent;
          document.getElementById('grid-filter').dispatchEvent(new Event('submit'));
        });
      });

      // Get pages
      const pages = await Promise.all(
        Array.from({length: pdf.numPages}, (_, i) => pdf.getPage(i + 1))
      );

      // Create sample cells for each page
      await Promise.all(pages.map(async page => {
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        
        await page.render({
          canvasContext: ctx,
          viewport: viewport
        }).promise;

        // Create sample cells
        const sampleSlider = document.createElement('div');
        sampleSlider.className = 'sample-slider w-100 h-100';

        const sampleContent = document.createElement('div');
        sampleContent.className = 'w-100 h-100 d-flex align-items-center justify-content-center user-select-disabled';
        sampleContent.style.overflow = 'scroll';
        sampleContent.style.backgroundImage = `url(${canvas.toDataURL()})`;
        sampleContent.style.backgroundSize = 'contain';
        sampleContent.style.backgroundRepeat = 'no-repeat';
        sampleContent.style.backgroundPosition = 'center';
        sampleContent.style.userSelect = 'none';
        sampleContent.style.pointerEvents = 'none';
        sampleContent.addEventListener('contextmenu', e => e.preventDefault());
        sampleContent.addEventListener('dragstart', e => e.preventDefault());

        sampleSlider.appendChild(sampleContent);
        viewerElements.sampleSlider.appendChild(sampleSlider);
      }));

      // Force layout calculation
      viewerElements.sampleSlider.offsetHeight;
      viewerElements.detailSlider.offsetHeight;

      // Initialize Flickity
      flkty = new Flickity(viewerElements.sampleSlider, {
        hash: true,
        fade: true,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: true,
        asNavFor: '#detailSlider',
        draggable: true,
        cellAlign: 'center',
        contain: true,
        adaptiveHeight: false,
        wrapAround: false
      });

      detailFlkty = new Flickity(viewerElements.detailSlider, {
        fade: true,
        draggable: false,
        prevNextButtons: false,
        pageDots: false,
        adaptiveHeight: false
      });
      
    } catch (error) {
      console.error('ERROR:', error);
    }
  };

  const handleGridClick = (e) => {
    const gridItem = e.target.closest('.grid-item');
    if (!gridItem) return;

    scrollToFilter();
    viewer.style.position = 'sticky';
    viewer.style.bottom = '0';
    
    updateViewer(gridItem);
  };

  window.addEventListener('scroll', handleScroll);
  filterInput.addEventListener('focus', scrollToFilter);
  gridFilter.addEventListener('submit', (e) => {
    e.preventDefault();
    scrollToFilter();
  });
  document.querySelector('.grid').addEventListener('click', handleGridClick);
});