import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';
import Packery from 'isotope-packery';

// Register packery layout mode with Isotope
Isotope.LayoutMode.modes['packery'] = Packery;

// Client-side grid initialization and filtering
document.addEventListener('DOMContentLoaded', function () {
  const samplesGrid = document.querySelector('.grid[samples-isotope]');
  const filterForm = document.querySelector('#grid-filter');
  
  if (samplesGrid) {
    try {
      fetch('/api/samples-kw')
        .then(res => res.json())
        .then(metadata => {
          // Add grid items
          metadata.forEach(item => {
            samplesGrid.innerHTML += `
              <div class="grid-item lift btn-grid-item user-select-disabled" data-keywords="${item.keywords}">
                <img class="img-fluid rounded-top-start rounded-bottom-end shadow-lg user-select-disabled" 
                     style="width: 100%; height: 100%; object-fit: cover;"
                     src="/assets/samples/thumbnails/${item.file}" 
                     alt="${item.file.replace('.png', '')}"
                     data-aos="fade-up"
                     data-aos-delay="100">
              </div>
            `;
          });

          // Add event listeners to prevent right-click and drag
          const gridItems = samplesGrid.querySelectorAll('.grid-item');
          gridItems.forEach(item => {
            item.addEventListener('contextmenu', e => e.preventDefault());
            item.addEventListener('dragstart', e => e.preventDefault());
            
            const img = item.querySelector('img');
            img.addEventListener('contextmenu', e => e.preventDefault());
            img.addEventListener('dragstart', e => e.preventDefault());
          });

          // Initialize after all images loaded
          imagesLoaded(samplesGrid, function() {
            const iso = new Isotope(samplesGrid, {
              itemSelector: '.grid-item',
              layoutMode: 'packery',
              packery: {
                gutter: 20,
                horizontalAlignment: 'center'
              }
            });

            window.addEventListener('resize', () => {
              iso.layout();
            });

            filterForm?.addEventListener('submit', e => {
              e.preventDefault();
              const searchTerm = e.target.filterInput.value.toLowerCase().trim();
              iso.arrange({
                filter: function(itemElem) {
                  const keywords = itemElem.dataset.keywords?.toLowerCase() || '';
                  return searchTerm === '' || keywords.includes(searchTerm);
                }
              });
            });
          });
        });
    } catch (err) {
      console.error('Grid error:', err);
    }
  }

  // About page grid
  const bioGrid = document.querySelector('#bio[data-isotope]');
  if (bioGrid) {
    try {
      console.log('About grid found');
      
      const iso = new Isotope(bioGrid, {
        itemSelector: '.bio',
        layoutMode: 'fitRows',
        percentPosition: true,
        hiddenStyle: { opacity: 0 },
        visibleStyle: { opacity: 1 },
        transitionDuration: '0.4s'
      });

      imagesLoaded(bioGrid, function() {
        console.log('About grid images loaded');
        const filterButtons = document.querySelectorAll('#bioTab .nav-link');
        console.log('Filter buttons found:', filterButtons.length);

        filterButtons.forEach(button => {
          button.addEventListener('click', e => {
            e.preventDefault();
            const filterValue = button.getAttribute('data-filter');
            console.log('Filtering about grid:', filterValue);
            filterButtons.forEach(btn => btn.classList.remove('active'));
            button.classList.add('active');
            iso.arrange({ filter: filterValue });
            updateVisibility(filterValue);
          });
        });
      });
    } catch (err) {
      console.error('Bio grid error:', err);
    }
  } else {
    console.log('Bio grid not found');
  }

  function updateVisibility(filterValue) {
    const items = document.querySelectorAll('.bio');
    items.forEach(item => {
      if (item.matches(filterValue)) {
        item.classList.add('is-visible');
      } else {
        item.classList.remove('is-visible');
      }
    });
  }



  // Charts grid initialization
  const chartsGrid = document.querySelector('.grid[charts-isotope]'); // Fix selector
  if (chartsGrid) {
    try {
      // Debug log grid items and their classes
      const items = chartsGrid.querySelectorAll('.chart-item');
      items.forEach(item => {
        console.log('Grid item classes:', item.className);
      });
  
      // Initialize Isotope
      const iso = new Isotope(chartsGrid, {
        itemSelector: '.chart-item',
        layoutMode: 'fitRows'
      });
  
      // Get filter buttons
      const filterBtns = document.querySelectorAll('#grid-filter .nav-link');
      
      // Add click handlers
      filterBtns.forEach(btn => {
        btn.addEventListener('click', () => {
          // Remove active from all buttons
          filterBtns.forEach(b => b.classList.remove('active'));
          
          // Add active to clicked button
          btn.classList.add('active');
          
          // Get category and fix selector syntax
          let filterValue = btn.getAttribute('data-category');
          
          // Debug logs
          console.log('Button clicked:', btn.textContent);
          console.log('Raw filter value:', filterValue);
          
          // Apply filter - remove leading dot for class matching
          if (filterValue === null || filterValue === '') {
            iso.arrange({filter: '*'});
          } else {
            // Remove leading dot if present
            filterValue = filterValue.replace(/^\./, '');
            console.log('Applied filter:', `.${filterValue}`);
            iso.arrange({filter: `.${filterValue}`});
          }
  
          // Log matched items
          const matched = chartsGrid.querySelectorAll(`.${filterValue}`);
          console.log('Matching items:', matched.length);
        });
      });
  
    } catch (err) {
      console.error('Charts grid error:', err);
    }
  }
});